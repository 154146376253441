
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';
import TitleTab from './_titleTab.vue';

import ShopEntityModule from '@/store/shops/entity';
import { ShopProcessedModel } from '@/interfaces/models/shop.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    FormBlock,
    TitleTab,
  },
  beforeMount() {
    this.shopId = this.$route.params.shopId as string;
  },
})
export default class ShopEntityEdit extends Form {
  shopId = '';
  isLoading = true;

  get currentTab(): string {
    return ShopEntityModule.tabsNav.profile?.id;
  }

  returnBefore(): void {
    this.$router.push({ name: 'shops' });
  }

  async mounted(): Promise<void> {
    await ShopEntityModule.initEdit({
      id: this.shopId,
      customerId: (ShopEntityModule.shop as ShopProcessedModel).clientId,
    });

    this.isLoading = false;
  }
}
