import { resolveComponent as _resolveComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-main-inner__wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_title_tab = _resolveComponent("title-tab")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_title_tab, _normalizeProps(_guardReactiveProps({ currentTab: _ctx.currentTab })), null, 16),
    _createElementVNode("div", null, [
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_gui_loader, {
            key: 0,
            "absolute-center": ""
          }))
        : (_openBlock(), _createBlock(_component_form_block, {
            key: 1,
            "type-action": "edit",
            onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save($event))),
            onReturnBefore: _ctx.returnBefore
          }, null, 8, ["onReturnBefore"]))
    ])
  ]))
}